import * as yup from 'yup';

import {
  getDataValue,
  getInputFileValue,
  getRenameValue
} from './reduxOutside';

import { validatePassword } from './helpersFunction';

// NEW YUP

export const editCalculationSchema = yup.object().shape({
  title: yup.string().required('Calculation name is required')
});

export const newCalculationSchema = yup.object().shape({
  title: yup.string().required('Calculation name is required')
});

export const saveAsCalculationSchema = yup.object().shape({
  title: yup.string().required('Calculation name is required')
});

export const renameFileSchema = yup.object().shape({
  title: yup.string().required('File Name is required')
});
export const renameReportSchema = yup.object().shape({
  title: yup.string().required('The report file name can not be empty')
});

export const renameDriveCalculationsSchema = yup.object().shape({
  title: yup.string().required('Calculation name is required')
});

export const accountSetting = yup.object().shape({
  name: yup
    .string()
    .required('Please enter your full name. Minimum 3 characters required.')
    .min(3, 'Please enter your full name. Minimum 3 characters.')
    .max(60, 'Maximum Length is 60 characters only')
});

export const addUserSchema = yup.object().shape({
  email: yup
    .string()
    .email('Invalid email address')
    .required('Email is required'),
  name: yup.string().required('Name is required'),
  company: yup.string().required('Company is required')
});

export const editUserSchema = yup.object().shape({
  email: yup
    .string()
    .email('invalid email address')
    .required('Email is required'),
  name: yup.string().required('Name is required')
});

export const addBillingMemberSchema = yup.object().shape({
  email: yup.string().required('Email is required'),
  plan: yup.string().required('Plan is required'),
  invoice: yup.number().required('Invoice is required')
});
// OLD YUP

export const loginSchema = yup.object().shape({
  email: yup
    .string()
    .required('Email is required')
    .email('Email must be a valid email'),
  password: yup
    .string()
    .required('Password is required')
    .min(3, 'Password must be at least 2 characters')
});

export const editEmailSchema = yup.object().shape({
  email: yup
    .string()
    .required('Email is required')
    .email('Invalid email format'),
  password: yup.string().required('Password is required')
});

export const recoverSchema = yup.object().shape({
  email: yup
    .string()
    .email('Please type a valid email format')
    .required('Email is required')
});

export const editPasswordSchema = yup.object().shape({
  newPassword: yup
    .string()
    .required('New password is required')
    .test(
      'newPassword',
      'The minimum password length is 8 characters and must contain at least 1 lowercase letter, 1 capital letter, 1 number and 1 special character',
      (value: any) => {
        const isValid = validatePassword(value);
        return isValid;
      }
    ),
  confirmNewPassword: yup
    .string()
    .required('Confirm new password is required')
    .oneOf([yup.ref('newPassword'), null], 'New Passwords does not match')
    .test(
      'confirmNewPassword',
      'The minimum password length is 8 characters and must contain at least 1 lowercase letter, 1 capital letter, 1 number and 1 special character',
      (value: any) => {
        const isValid = validatePassword(value);
        return isValid;
      }
    ),
  currentPassword: yup.string().required('Current password is required')
});

export const forgotPasswordSchema = yup.object().shape({
  email: yup.string().email('Please type a valid email format')
});

export const renameSchema = yup.object().shape({
  textField: yup
    .string()
    .test(
      'sameNameTest',
      'Calculation name already existing',
      function (value) {
        if (value === null || value === undefined) {
          return true;
        }

        const data = getDataValue();
        for (let i = 0; i < data.length; i++) {
          if (data[i].title === 'CALCULATION') {
            if (value === data[i].title && getRenameValue().id !== data[i].id) {
              return false;
            }
          }
        }

        return true;
      }
    )
    .required('Calculation name is required')
});

export const renameInputFileSchema = yup.object().shape({
  textField: yup
    .string()
    .test('sameNameTest', 'Input File name already existing', function (value) {
      if (value === null || value === undefined) {
        return true;
      }

      const data = getInputFileValue();
      for (let i = 0; i < data.length; i++) {
        if (data[i].title !== null) {
          if (value === data[i].title && getRenameValue().id !== data[i].id) {
            return false;
          }
        }
      }

      return true;
    })
    .required('Input File name is required')
});

export const addFolderSchema = yup.object().shape({
  title: yup.string().required('New folder name is required')
});

export const newCalSchema = yup.object().shape({
  calculationName: yup.string().required('Calculation Name is required')
  // description: yup.string().required("Description is required"),
});

export const createCalculationSchema = yup.object().shape({
  title: yup.string().required('Calculation Name is required')
  // description: yup.string().required("Description is required"),
});

export const renameFolderSchema = yup.object().shape({
  title: yup.string().required('Folder Name is required')
});

export const makeCopyCalSchema = yup.object().shape({
  title: yup.string().required('Calculation Name is required')
});

export const renameCalculationsSchema = yup.object().shape({
  title: yup.string().required('Calculation Name is required')
});

export const validateProfileChange = yup.object().shape({
  email: yup
    .string()
    .email('Invalid email format')
    .required('Email cannot be empty'),
  password: yup.string().required('Password cannot be empty')
});

export const editCalculationInfoSchema = yup.object().shape({
  title: yup.string().required('Calculation Name is required')
});

export const saveAsCalSchema = yup.object().shape({
  calculationName: yup.string().required('Calculation Name is required'),
  folderName: yup.string().required('Folder Name is required')
});

export const loadInputFileSchema = yup.object().shape({
  inputFile: yup.string().required('Input File is required')
});

export const saveAsInputFileSchema = yup.object().shape({
  title: yup.string().required('Input File Name is required')
});

export const renameCurrentInputFileSchema = yup.object().shape({
  title: yup.string().required('Input File Name is required')
});

export const renameChatSessionSchema = yup.object().shape({
  conversationName: yup
    .string()
    .required('Conversation Name is required')
    .max(30, 'Conversation Name cannot exceed 30 characters')
});

export const renameSourceFileSchema = yup.object().shape({
  title: yup.string().required('Title File is required')
});

export const addCompanySchema = yup.object().shape({
  name: yup.string().required('Company name is required')
});
