import { Tooltip } from '@mui/material';
import { CSSProperties } from 'react';
import { DraggableProvided } from 'react-beautiful-dnd';
import useHover from '../../../hooks/useHover';
import { mapIconType } from './mapIconType';

type Props = {
  icon: Partial<keyof typeof mapIconType> | React.ReactNode;
  className?: string;
  color?: string;
  hoverColor?: string;
  size?: number;
  useHoverFn?: boolean;
  cursor?: string;
  styles?: CSSProperties;
  onClick?: React.MouseEventHandler;
  provided?: DraggableProvided;
  notclickable?: boolean;
  id?: string;
  tooltip?: string;
  dataTestId?: string;
  onMouseEnter?: React.MouseEventHandler;
  onMouseLeave?: React.MouseEventHandler;
};

const Icon = ({
  icon,
  className,
  color,
  hoverColor,
  size,
  useHoverFn,
  cursor,
  styles,
  onClick,
  provided,
  notclickable,
  id,
  tooltip,
  dataTestId,
  onMouseEnter,
  onMouseLeave
}: Props) => {
  const [ref, isHovered] = useHover();
  const dragHandle = provided ? { ...provided.dragHandleProps } : undefined;

  return (
    <div
      ref={ref}
      {...dragHandle}
      className={className}
      style={{
        ...styles,
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        color: useHoverFn && isHovered ? hoverColor : color,
        fontSize: size,
        cursor: cursor,
        pointerEvents: notclickable === true ? 'none' : 'auto'
      }}
      // className={className}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      id={id ?? undefined}
      data-testid={dataTestId}
    >
      {tooltip ? (
        <Tooltip title={tooltip}>
          <>{mapIconType[icon as keyof typeof mapIconType] ?? icon}</>
        </Tooltip>
      ) : (
        (mapIconType[icon as keyof typeof mapIconType] ?? icon)
      )}
    </div>
  );
};

export default Icon;

Icon.defaultProps = {
  color: '#686D8E',
  size: 16,
  useHoverFn: false,
  hoverColor: '#118CFF',
  cursor: 'pointer'
};
