import { API_CACHE_PREFIX } from './useAPI';

const TOKEN_STORAGE = 'app__token';
const EXPIRE_TOKEN_TIME = 'app__token_expired';
const COMMUNITY_WORKSPACE_ID = 'app__community_workspace_id';
const ENTERPRISE_WORKSPACE_ID = 'app__enterprise_workspace_id';
const PERSONAL_WORKSPACE_ID = 'app__personal_workspace_id';
const USER_PROFILE = 'app__user_profile';
const USER_DETAILS = 'app__user_details';
const USER_ID = 'app__user_id';
const CALCULATION_ID = 'app__calculation_id';
const USER_TUTORIALS = 'app__tutorials';
const TUTORIALS_LIST = 'app__tutorials_list';
const PREVENT_CHANGES = 'app__prevent_changes';
const IS_CONSENTS = 'app__is_consents';
const IS_MOBILE = 'app__is_mobile';
const DRIVE_ROLE = 'app__drive_role';
const DRIVE_STATUS = 'app__drive_status';
const USER_TYPE = 'app__user_type';

export function setToken(value: any) {
  localStorage.setItem(TOKEN_STORAGE, value);
}

export function getToken() {
  return localStorage.getItem(TOKEN_STORAGE);
}

export function setProfile(value: any) {
  localStorage.setItem(USER_PROFILE, value);
}

export function setUserId(value: any) {
  localStorage.setItem(USER_ID, value);
}

export function setExpiredToken(value: any) {
  localStorage.setItem(EXPIRE_TOKEN_TIME, value);
}

export function setIsMobile(value: any) {
  localStorage.setItem(IS_MOBILE, value);
}

export function setCommunityWorkspaceId(value: any) {
  localStorage.setItem(COMMUNITY_WORKSPACE_ID, value);
}

export function setEnterpriseWorkspaceId(value: any) {
  localStorage.setItem(ENTERPRISE_WORKSPACE_ID, value);
}

export function setPersonalWorkspaceId(value: any) {
  localStorage.setItem(PERSONAL_WORKSPACE_ID, value);
}

export function setUserDetails(value: Record<string, string>) {
  return localStorage.setItem(USER_DETAILS, JSON.stringify(value));
}

export function setUserTutorials(value: Record<string, string>) {
  return localStorage.setItem(USER_TUTORIALS, JSON.stringify(value));
}

export function setTutorialsList(value: any) {
  localStorage.setItem(TUTORIALS_LIST, JSON.stringify(value));
}

export function setPreventChangesCalculation(value: any) {
  localStorage.setItem(PREVENT_CHANGES, JSON.stringify(value));
}

export function setIsConsents(value: any) {
  localStorage.setItem(IS_CONSENTS, JSON.stringify(value));
}

export function setBusinessDriveStatus(value: any) {
  localStorage.setItem(DRIVE_STATUS, JSON.stringify(value));
}

export function setUserType(value: string) {
  localStorage.setItem(USER_TYPE, value);
}

export function getUserProfile() {
  return localStorage.getItem(USER_PROFILE);
}

export function getUserId() {
  return localStorage.getItem(USER_ID);
}

export function getIsMobile() {
  return JSON.parse(localStorage.getItem(IS_MOBILE) || 'false');
}

export function getUserDetails() {
  return JSON.parse(localStorage.getItem(USER_DETAILS) || '{}');
}

export function getCommunityWorkspaceId() {
  return localStorage.getItem(COMMUNITY_WORKSPACE_ID);
}

export function getPersonalWorkspaceId() {
  return localStorage.getItem(PERSONAL_WORKSPACE_ID);
}

export function getEnterpriseWorkspaceId() {
  return localStorage.getItem(ENTERPRISE_WORKSPACE_ID);
}

export function setCalculationId(calId: string) {
  return localStorage.setItem(CALCULATION_ID, calId);
}

export function getCalculationId() {
  return localStorage.getItem(CALCULATION_ID);
}

export function getUserTutorials() {
  return JSON.parse(localStorage.getItem(USER_TUTORIALS) || '{}');
}

export function getTutorialsList() {
  return JSON.parse(localStorage.getItem(TUTORIALS_LIST) || '[]');
}

export function getPreventChangesCalculation() {
  return JSON.parse(localStorage.getItem(PREVENT_CHANGES) || '{}');
}

export function getIsConsents() {
  return JSON.parse(localStorage.getItem(IS_CONSENTS) || '{}');
}

export function setBusinessDriveRole(value: string) {
  localStorage.setItem(DRIVE_ROLE, JSON.stringify(value));
}

export function getBusinessDriveRole() {
  return JSON.parse(localStorage.getItem(DRIVE_ROLE) || '{}');
}

export function getBusinessDriveStatus() {
  return JSON.parse(localStorage.getItem(DRIVE_STATUS) || '{}');
}

export function getUserType() {
  return localStorage.getItem(USER_TYPE) || '';
}

export function checkProfileRole(role: any) {
  switch (role) {
    case 'REKAVA_ADMIN':
    case 'COMPANY_ADMIN':
      return 1;
    case 'PRINCIPAL':
      return 2;
    case 'COMPOSER_ADMIN':
      return 3;
    case 'COMPOSER':
      return 4;
    case 'LOGGER':
      return 5;
    default:
      return 6;
  }
}

export function checkExpireTime() {
  const expiresAt = localStorage.getItem(EXPIRE_TOKEN_TIME);
  if (!expiresAt) return false;

  const time = new Date().getTime();

  const expire = new Date(
    localStorage.getItem(EXPIRE_TOKEN_TIME) || 0
  ).getTime();

  return time > expire;
}

export const composerDataRemoveItem = () => {
  const items = [];
  for (let i = 0; i < localStorage.length; i++) {
    items[i] = localStorage.key(i);
  }

  const composerLocalData = items.filter((data: any) =>
    data.includes('composer_data__')
  );

  composerLocalData.forEach((data: any) => {
    localStorage.removeItem(data);
  });
};

export function clearStorage() {
  localStorage.removeItem(TOKEN_STORAGE);
  localStorage.removeItem(EXPIRE_TOKEN_TIME);
  localStorage.removeItem(ENTERPRISE_WORKSPACE_ID);
  localStorage.removeItem(PERSONAL_WORKSPACE_ID);
  localStorage.removeItem(COMMUNITY_WORKSPACE_ID);
  localStorage.removeItem(USER_PROFILE);
  localStorage.removeItem(USER_DETAILS);
  localStorage.removeItem(COMMUNITY_WORKSPACE_ID);
  localStorage.removeItem('sidebar');
  localStorage.removeItem(PREVENT_CHANGES);
  localStorage.removeItem(IS_CONSENTS);
  localStorage.removeItem(IS_MOBILE);
  localStorage.removeItem(DRIVE_ROLE);
  localStorage.removeItem(USER_TYPE);
  for (let i = 0, len = localStorage.length; i < len; ++i) {
    const key = localStorage.key(i);
    if (key?.startsWith(`${API_CACHE_PREFIX}`)) localStorage.removeItem(key);
  }
  composerDataRemoveItem();
}
