import { styled } from '@mui/material';
import Box from '@mui/material/Box';

interface Props {
  size?: string;
}

export const Spinner = styled('div')<Props>(props => ({
  '@keyframes rotate': {
    to: {
      transform: 'rotate(360deg)'
    }
  },
  animation: 'rotate 1s infinite linear',
  display: 'inline-block',
  width:
    props.size === 'SMALL' ? '15px' : props.size === 'medium' ? '30px' : '56px',
  height:
    props.size === 'SMALL' ? '15px' : props.size === 'medium' ? '30px' : '56px',
  border:
    props.size === 'SMALL'
      ? '3.5px solid #f3f3f3'
      : props.size === 'medium'
        ? '5px solid #f3f3f3'
        : '8px solid #1D9BF040',
  borderRadius: '50%',
  borderTopColor: '#1D9BF0'
}));

export const ContainerLoading = styled(Box)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  zIndex: 1
});
