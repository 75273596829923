import {
  AceCitationI,
  ChatSessionI,
  SqlCitationI
} from '../../pages/askAce/askAce.type';
import { IRatingType } from '../../service/AceService';
import {
  FETCH_CHAT_HISTORIES,
  FETCH_CHAT_FAILED,
  FETCH_CHAT_SUCCESS,
  UPDATE_ACE_ANSWER,
  FailedActionI,
  LoadingActionI,
  SuccessActionI,
  UpdateActionI,
  ADD_CHAT_HISTORY,
  AddChatActionI,
  UPDATE_CLASSIFICATION,
  AddClassificationI,
  UPDATE_CHAT_HISTORY,
  UpsertActionI,
  SET_USER_FEEDBACK,
  SetUserFeedbackI,
  ResetActionI,
  RESET_CHAT_HISTORIES,
  ACE_ANSWER_END,
  AnswerEndI,
  FetchChatSessionsI,
  FETCH_CHAT_SESSIONS,
  SET_SESSION_ID,
  SetSessionIdI,
  SET_CHAT_SESSIONS_PAGE,
  SetChatSessionsPageI,
  SetSessionErrorI,
  SET_SESSION_ERROR,
  FetchingActionI,
  FETCH_DATA_SOURCE,
  SetSqlCitationsI,
  SET_SQL_CITATIONS,
  SET_USER_AGENT,
  SetUserAgentI,
  WaitingForAnswerI,
  WAITING_FOR_ANSWER,
  RemoveLatestItemI,
  REMOVE_LATEST_ITEM,
  AddNewSessionI,
  ADD_NEW_SESSION,
  SetActiveCitationI,
  ACTIVE_CITATION
} from '../types/aceTypes';
import { v4 as uuidV4 } from 'uuid';

export interface ChatDataT {
  id: string;
  question?: string;
  answer?: string;
  classification?: string;
  certainty?: number;
  upvote?: boolean;
  downvote?: boolean;
  ratingtype?: IRatingType[];
  description?: string;
  citations: AceCitationI[];
  sqlCitations: SqlCitationI[];
}

export interface ActiveCitationI {
  id: string;
  selected: string;
}

export interface ChatSessionsPageI {
  page: number;
  totalPage: number;
}

export interface AceStateI {
  chatHistories: ChatDataT[];
  isLoading: boolean;
  isStreaming: boolean;
  message?: string;
  chatSessions: ChatSessionI[];
  sessionId: null | string;
  chatSessionsPage: ChatSessionsPageI;
  error?: boolean;
  isFetching: boolean;
  hasAgent: boolean;
  activeCitation: null | ActiveCitationI;
}

const AceState: AceStateI = {
  isLoading: false,
  isStreaming: false,
  message: '',
  chatHistories: [],
  chatSessions: [],
  sessionId: null,
  chatSessionsPage: {
    page: 0,
    totalPage: 0
  },
  error: false,
  isFetching: false,
  hasAgent: false,
  activeCitation: null
};

const initialChatData: ChatDataT = {
  id: '',
  question: '',
  answer: '',
  citations: [],
  sqlCitations: []
};

type Action =
  | LoadingActionI
  | FailedActionI
  | SuccessActionI
  | UpdateActionI
  | AddChatActionI
  | AddClassificationI
  | UpsertActionI
  | SetUserFeedbackI
  | ResetActionI
  | AnswerEndI
  | FetchChatSessionsI
  | SetSessionIdI
  | SetChatSessionsPageI
  | SetSessionErrorI
  | FetchingActionI
  | SetSqlCitationsI
  | SetUserAgentI
  | WaitingForAnswerI
  | RemoveLatestItemI
  | AddNewSessionI
  | SetActiveCitationI;

const aceReducer = (state: AceStateI = AceState, action: Action) => {
  switch (action.type) {
    case FETCH_CHAT_HISTORIES:
      return { ...state, isLoading: action.isLoading };
    case FETCH_CHAT_FAILED:
      return { ...state, isLoading: action.isLoading, message: action.message };
    case FETCH_CHAT_SUCCESS:
      const histories = [...action.chatHistories];
      histories.reverse();
      // reverse response data to display The most recent messages at the bottom
      // see: https://digitive.atlassian.net/jira/software/projects/ACE/boards/5?assignee=70121%3A20eb8975-3fec-4de7-adc8-a2d0dcc1fe93&selectedIssue=ACE-18
      return { ...state, chatHistories: histories };
    case ADD_CHAT_HISTORY:
      return {
        ...state,
        isLoading: true,
        chatHistories: [
          ...state.chatHistories,
          { ...initialChatData, id: uuidV4(), question: action.question }
        ]
      };
    case UPDATE_ACE_ANSWER:
      const updatedAnswer = [...state.chatHistories].map(
        (history: ChatDataT, index: number) => {
          if (index === state.chatHistories.length - 1) {
            history.answer = history.answer + action.answer;
          }
          return history;
        }
      );
      return {
        ...state,
        // isStreaming: true,
        chatHistories: updatedAnswer
      };
    case UPDATE_CLASSIFICATION:
      const updatedClassification = [...state.chatHistories].map(
        (history: ChatDataT, index: number) => {
          if (index === state.chatHistories.length - 1) {
            history.classification = action.classification;
          }
          return history;
        }
      );
      return {
        ...state,
        chatHistories: updatedClassification
      };
    case UPDATE_CHAT_HISTORY:
      const updatedSource = [...state.chatHistories].map(
        (history: ChatDataT, index: number) => {
          let updatedData = {};
          if (index === state.chatHistories.length - 1) {
            updatedData = action.chatHistory;
          }
          const newUpdatedData = {
            ...updatedData,
            sqlCitations: history.sqlCitations
          };
          return { ...history, ...newUpdatedData };
        }
      );
      return {
        ...state,
        chatHistories: updatedSource
      };
    case SET_USER_FEEDBACK:
      const updatedUserFeedback = [...state.chatHistories].map(
        (item: ChatDataT) => {
          const isUpvote = action.voteType === 'LIKE';
          const isDownVote = action.voteType === 'DISLIKE';
          if (item.id === action.id) {
            return {
              ...item,
              upvote:
                item.upvote && isUpvote
                  ? false
                  : !item.upvote && isUpvote
                    ? true
                    : isDownVote
                      ? false
                      : item.upvote,
              downvote:
                item.downvote && isDownVote
                  ? false
                  : !item.downvote && isDownVote
                    ? true
                    : isUpvote
                      ? false
                      : item.downvote
            };
          }
          return { ...item };
        }
      );
      return {
        ...state,
        chatHistories: updatedUserFeedback
      };
    case RESET_CHAT_HISTORIES:
      return {
        ...state,
        chatHistories: []
      };
    case ACE_ANSWER_END:
      return {
        ...state,
        isStreaming: false
      };
    case FETCH_CHAT_SESSIONS:
      return {
        ...state,
        chatSessions: action.chatSessions
      };
    case SET_SESSION_ID:
      return {
        ...state,
        sessionId: action.sessionId
      };
    case SET_CHAT_SESSIONS_PAGE:
      return {
        ...state,
        chatSessionsPage: action.data
      };

    case SET_SESSION_ERROR:
      return {
        ...state,
        error: action.payload
      };
    case FETCH_DATA_SOURCE:
      return { ...state, isFetching: action.isFetching };

    case SET_SQL_CITATIONS:
      return {
        ...state,
        chatHistories: [...state.chatHistories].map((item, index) => {
          if (index !== state.chatHistories.length - 1) {
            return item;
          }
          return {
            ...item,
            citations: !action.data.citations?.length
              ? item.citations
              : action.data.citations,
            sqlCitations: !action.data.sqlCitations?.length
              ? item.sqlCitations
              : action.data.sqlCitations?.map(item => {
                  return {
                    ...item,
                    id: action.data.id
                  };
                })
          };
        })
      };

    case SET_USER_AGENT:
      return {
        ...state,
        hasAgent: action.payload
      };

    case WAITING_FOR_ANSWER:
      return {
        ...state,
        isStreaming: action.payload
      };

    case REMOVE_LATEST_ITEM:
      return {
        ...state,
        isLoading: false,
        chatHistories: state.chatHistories.filter(item => !!item.answer)
      };

    case ADD_NEW_SESSION:
      return {
        ...state,
        chatSessions: [action.chatSession, ...state.chatSessions]
      };

    case ACTIVE_CITATION:
      return {
        ...state,
        activeCitation: action.payload
      };

    default:
      return state;
  }
};

export default aceReducer;
