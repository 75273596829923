import {
  BigPath,
  BigPathBox,
  Body,
  Container,
  ErrorInputText,
  FlyingContainer,
  Foot,
  Form,
  ImagePreview,
  InformationWrapper,
  InputSelect,
  InputText,
  Label,
  LabelInputText,
  NoPaddingAutocomplete,
  OptionBox,
  PreviewContainer,
  PreviewDesc,
  XButton
} from './elements';
import { Controller, useForm } from 'react-hook-form';
import {
  Box,
  Checkbox,
  Divider,
  Fade,
  IconButton,
  InputAdornment,
  ListItemIcon,
  Menu,
  TextField,
  useTheme
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  addBillingMemberSchema,
  addFolderSchema,
  addUserSchema,
  createCalculationSchema,
  editCalculationInfoSchema,
  editCalculationSchema,
  editEmailSchema,
  editPasswordSchema,
  editUserSchema,
  forgotPasswordSchema,
  loadInputFileSchema,
  makeCopyCalSchema,
  newCalculationSchema,
  renameCalculationsSchema,
  renameCurrentInputFileSchema,
  renameDriveCalculationsSchema,
  renameFileSchema,
  renameFolderSchema,
  renameReportSchema,
  saveAsCalculationSchema,
  renameChatSessionSchema,
  renameSourceFileSchema,
  addCompanySchema
} from '../../../../utils/validation';
import {
  bigPathFormModalActionPopUp,
  formModalSetupActionPopUp,
  formModalSubmitActionPopUp,
  reducerModifierCloseEventActionPopUp,
  selectFormModalActionPopUp
} from '../../../../store/appState/popUp/formModal/formModalAction';
import {
  fetchTeamDrives,
  submitLoading,
  uploadLoggerPreview
} from '../../../../store/appState/global/globalActions';
import { findError, getRandomNumber } from '../../../../utils/helpersFunction';
import {
  getPersonalWorkspaceId,
  setPreventChangesCalculation
} from '../../../../utils/storage';
import {
  setActionState,
  setCalculationTarget,
  setPreventChanges
} from '../../../../store/composer/composerChanges/composerChangesActions';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import Button from '../../../atoms/Button';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { CopyToDrives } from './components/copyToDrives';
import Icon from '../../../atoms/Icon';
import Input from '../../../atoms/Input';
import Loading from '../../../atoms/loading';
import { ManageMembers } from './components/manageMembers';
import Modal from '@mui/material/Modal';
import { ReduxState } from '../../../../store';
import ReportService from '../../../../service/ReportServices';
import Service from '../../../../service/generic/generic';
import StyledButton from '../../../atoms/Button';
import Text from '../../../atoms/Text';
import { UpgradePlansPopUp } from './components/upgradePlans';
import { getUserCalculationLimit } from '../../../../store/actions/userAction';
import images from '../../../../assets';
import { overrideCalculation } from '../../../../store/actions/calculationsActions';
import { setActions } from '../../../../store/actions/logger.actions';
import { toastSetupActionPopUp } from '../../../../store/appState/popUp/toast/toastAction';
import { useHistory } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';

export default function FormModal() {
  // SERVICE
  const theme = useTheme();
  let service: any;
  let backendResponse: any;
  // const [inputValue, setInputValue] = useState<any>();

  const [typePassword, setTypePassword] = useState<any>(true);
  const [newPassword, setNewPassword] = useState<any>(true);
  const [confirmNewPassword, setConfirmNewPassword] = useState<any>(true);

  // REDUX USESELECTOR, USEHISTORY, AND USEDISPATCH

  const { closeEvent, dataFormModal } = useSelector(
    (state: ReduxState) => state.formModalPopUp,
    shallowEqual
  );

  const { dataHeadOfCalculation } = useSelector(
    (state: ReduxState) => state.composer,
    shallowEqual
  );

  const { userProfile } = useSelector(
    (state: ReduxState) => state.users,
    shallowEqual
  );

  const { subscription } = userProfile;

  // WORKSPACE ? FOLDER ID
  const { isLoadingSubmit, workspace, loggerPreview } = useSelector(
    (state: ReduxState) => state.global,
    shallowEqual
  );

  // NEW ID FROM CREATE CALCULATION
  const { id } = useSelector(
    (state: ReduxState) => state.calculations,
    shallowEqual
  );

  // GET CAL NAME FOR ERROR HANDLING
  const { calName } = useSelector(
    (state: ReduxState) => state.calInfo,
    shallowEqual
  );

  const history = useHistory();
  const dispatch = useDispatch();

  // USEEFFECT

  useEffect(() => {
    const unsubscribe = modalHandler(dataFormModal);
    return unsubscribe;
  }, [dataFormModal.type, dataFormModal.open]);

  const handleSetValue = (data: any) => {
    clearErrors();
    for (let i = 0; i < data.length; i++) {
      if (data[i].type === 'TEXT') {
        setValue(data[i].name, data[i].defaultValue);
      }
      if (data[i].type === 'CHECKBOX') {
        setValue(data[i].name, data[i].value);
      }
    }
  };

  const changePasswordShow = (type: any) => {
    if (type === 'currentPassword') {
      setTypePassword(!typePassword);
    } else if (type === 'newPassword') {
      setNewPassword(!newPassword);
    } else {
      setConfirmNewPassword(!confirmNewPassword);
    }
  };

  const getVariable = (type: any) => {
    if (type === 'currentPassword') {
      if (!typePassword) return 'text';
      else return 'password';
    } else if (type === 'newPassword') {
      if (!newPassword) return 'text';
      else return 'password';
    } else {
      if (!confirmNewPassword) return 'text';
      else return 'password';
    }
  };

  const modalHandler = (data: any) => {
    switch (data.type) {
      case 'EDIT_CALCULATION_FROM_COMPOSER':
      case 'NEW_CALCULATION_FROM_COMPOSER':
      case 'RENAME_CALCULATION_FROM_MYCALCULATION':
      case 'MAKE_COPY_CALCULATION_FROM_MYCALCULATION':
      case 'EDIT_CALCULATION_INFO_FROM_MYCALCULATION':
      case 'RENAME_FOLDER':
      case 'ADD_FOLDER':
      case 'CREATE_CALCULATION_FROM_MYCALCULATION':
      case 'RENAME_INPUT_FILE':
      case 'RENAME_FILE':
      case 'RENAME_REPORT':
      case 'EDIT_ACCOUNT_MANAGER':
      case 'COMPOSER_DATA_CHANGES_INPUT_DATA_CALCULATION':
      case 'CREATE_INPUT_FILE_FROM_COMPOSER_NAVBAR':
      case 'RENAME_TEAM_DRIVES':
      case 'NEW_CALCULATION_TEAM_DRIVES':
      case 'RENAME_CALCULATION_FROM_DRIVE':
      case 'EDIT_CALCULATION_INFO_FROM_DRIVE':
      case 'RENAME_CHAT_SESSION':
      case 'RENAME_SOURCE_FILE':
        handleSetValue(dataFormModal.content.body);
        break;
      default:
        // NOTHING TO DO HERE
        break;
    }
  };

  const checkTitleName = (name: any) => {
    switch (name) {
      case 'plan':
        return 15;
      case 'status':
        return 45;
      default:
        return 4;
    }
  };

  useEffect(() => {
    if (closeEvent) {
      closeFormModal();
      dispatch(reducerModifierCloseEventActionPopUp(false));
    }
  }, [closeEvent]);

  // REDIRECT AFTER CREATE CALCULATION
  useEffect(() => {
    if (dataFormModal.type === 'CREATE_CALCULATION_FROM_MYCALCULATION') {
      history.replace(`/composer/${id}/${workspace}`);
    }
  }, [id]);

  // USEFORM

  const {
    reset,
    setValue,
    setError,
    clearErrors,
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<any>({
    resolver:
      dataFormModal.type === 'EDIT_CALCULATION_FROM_COMPOSER'
        ? yupResolver(editCalculationSchema)
        : dataFormModal.type === 'RENAME_CALCULATION_FROM_MYCALCULATION'
          ? yupResolver(renameCalculationsSchema)
          : dataFormModal.type === 'MAKE_COPY_CALCULATION_FROM_MYCALCULATION'
            ? yupResolver(makeCopyCalSchema)
            : dataFormModal.type === 'EDIT_CALCULATION_INFO_FROM_MYCALCULATION'
              ? yupResolver(editCalculationInfoSchema)
              : dataFormModal.type === 'RENAME_FOLDER'
                ? yupResolver(renameFolderSchema)
                : dataFormModal.type === 'ADD_FOLDER'
                  ? yupResolver(addFolderSchema)
                  : dataFormModal.type ===
                      'CREATE_CALCULATION_FROM_MYCALCULATION'
                    ? yupResolver(createCalculationSchema)
                    : dataFormModal.type === 'RENAME_INPUT_FILE'
                      ? yupResolver(renameCurrentInputFileSchema)
                      : dataFormModal.type === 'NEW_CALCULATION_FROM_COMPOSER'
                        ? yupResolver(newCalculationSchema)
                        : dataFormModal.type === 'SAVE_AS_FROM_COMPOSER'
                          ? yupResolver(saveAsCalculationSchema)
                          : dataFormModal.type === 'RENAME_FILE'
                            ? yupResolver(renameFileSchema)
                            : dataFormModal.type === 'RENAME_REPORT'
                              ? yupResolver(renameReportSchema)
                              : dataFormModal.type ===
                                  'ADD_USER_USER_MANAGEMENT'
                                ? yupResolver(addUserSchema)
                                : dataFormModal.type ===
                                    'COMPOSER_DATA_CHANGES_INPUT_DATA_CALCULATION'
                                  ? yupResolver(createCalculationSchema)
                                  : dataFormModal.type ===
                                      'EDIT_ACCOUNT_MANAGER'
                                    ? yupResolver(editUserSchema)
                                    : dataFormModal.type ===
                                        'EDIT_PASSWORD_ACCOUNT_SETTING'
                                      ? yupResolver(editPasswordSchema)
                                      : dataFormModal.type === 'FORGOT_PASSWORD'
                                        ? yupResolver(forgotPasswordSchema)
                                        : dataFormModal.type ===
                                            'EDIT_EMAIL_ACCOUNT_SETTING'
                                          ? yupResolver(editEmailSchema)
                                          : dataFormModal.type ===
                                              'LOAD_INPUT_FILE'
                                            ? yupResolver(loadInputFileSchema)
                                            : dataFormModal.type ===
                                                'NEW_CALCULATION_TEAM_DRIVES'
                                              ? yupResolver(
                                                  newCalculationSchema
                                                )
                                              : dataFormModal.type ===
                                                  'ADD_BILLING_MEMBER'
                                                ? yupResolver(
                                                    addBillingMemberSchema
                                                  )
                                                : dataFormModal.type ===
                                                    'MAKE_COPY_CALCULATION_FROM_DRIVE'
                                                  ? yupResolver(
                                                      makeCopyCalSchema
                                                    )
                                                  : dataFormModal.type ===
                                                      'RENAME_CALCULATION_FROM_DRIVE'
                                                    ? yupResolver(
                                                        renameDriveCalculationsSchema
                                                      )
                                                    : dataFormModal.type ===
                                                        'EDIT_CALCULATION_INFO_FROM_DRIVE'
                                                      ? yupResolver(
                                                          editCalculationInfoSchema
                                                        )
                                                      : dataFormModal.type ===
                                                          'RENAME_CHAT_SESSION'
                                                        ? yupResolver(
                                                            renameChatSessionSchema
                                                          )
                                                        : dataFormModal.type ===
                                                            'RENAME_SOURCE_FILE'
                                                          ? yupResolver(
                                                              renameSourceFileSchema
                                                            )
                                                          : dataFormModal.type ===
                                                              'ADD_COMPANY_USER_MANAGEMENT'
                                                            ? yupResolver(
                                                                addCompanySchema
                                                              )
                                                            : undefined,
    mode: 'all'
    // reValidateMode: 'onSubmit'
  });

  // MODAL INSIDE MODAL

  //// SELECT MENU
  const [openModalSelect, setOpenModalSelect] = useState<any>({
    value: false,
    anchorPosition: null,
    contentName: null,
    contentArray: null,
    createNew: false,
    loadNew: false,
    staticTitle: null
  });

  const handlerModalSelect = (typeAction: any, event?: any, payload?: any) => {
    if (typeAction === 'OPEN') {
      setOpenModalSelect({
        value: true,
        anchorPosition: event.currentTarget,
        contentName: payload.name,
        contentArray: payload.contentValue,
        createNew: payload.createNew,
        loadNew: payload.loadNew,
        staticTitle: payload.staticTitle
      });
    } else if (typeAction === 'CLOSE') {
      setOpenModalSelect({
        value: false,
        anchorPosition: null,
        contentName: null,
        contentArray: null,
        createNew: false,
        loadNew: false,
        staticTitle: null
      });
    } else {
      dispatch(selectFormModalActionPopUp(payload));
      setValue(payload.contentName, payload.target.title ?? payload.target);
      setOpenModalSelect({
        value: false,
        anchorPosition: null,
        contentName: null,
        contentArray: null,
        createNew: false,
        loadNew: false,
        staticTitle: null
      });
      createNewTrigger(payload);
    }
  };

  const modalSelect = () => (
    <Menu
      open={openModalSelect.value}
      onClose={() => handlerModalSelect('CLOSE')}
      anchorEl={openModalSelect.anchorPosition}
      MenuListProps={{
        disablePadding: true
      }}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: 'visible',
          boxShadow: '0px 0px 12px rgba(104, 109, 142, 0.24)',
          mt: 0.5
        }
      }}
    >
      <OptionBox>
        {openModalSelect.createNew && (
          <>
            <div
              className="createNewInputFile"
              onClick={e =>
                handlerModalSelect('CHANGE_OPTION', e, {
                  contentName: openModalSelect.contentName,
                  target: 'Create New'
                })
              }
            >
              + Create New
            </div>
            <Divider />
          </>
        )}

        {openModalSelect.loadNew && (
          <>
            <div className="loadNewInputFIle">
              {openModalSelect.staticTitle}
            </div>
            <Divider />
          </>
        )}

        {openModalSelect.contentArray?.map((value: any) => (
          <div
            key={value.id ?? value}
            className="optionValue"
            onClick={e =>
              handlerModalSelect('CHANGE_OPTION', e, {
                contentName: openModalSelect.contentName,
                target: value
              })
            }
            data-testid={value}
            style={
              openModalSelect.loadNew
                ? {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start'
                  }
                : {}
            }
          >
            {openModalSelect.loadNew && (
              <img src={images.fileIconInputFile} width="25px" alt="img" />
            )}
            {value instanceof Object ? value.title : value}
          </div>
        ))}
      </OptionBox>
    </Menu>
  );

  //// BIG PATH MENU

  const [openModalBigPath, setOpenModalBigPath] = useState<any>({
    value: false,
    anchorPosition: null,
    contentName: null,
    parentId: null,
    currentValue: null
  });

  const handlerModalBigPath = async (
    typeAction: any,
    event?: any,
    payload?: any
  ) => {
    switch (typeAction) {
      case 'OPEN':
        setOpenModalBigPath({
          value: true,
          anchorPosition: event.currentTarget,
          contentName: payload.name,
          parentId: payload.parentId,
          currentValue: payload.currentValue
        });
        break;
      case 'CLOSE':
        setOpenModalBigPath({
          value: false,
          anchorPosition: null,
          contentName: null,
          parentId: null,
          currentValue: null
        });
        break;
      case 'CLICK':
        try {
          service = new Service('folders');
          backendResponse = await service.getSubresource(
            payload.id,
            'view?type=FOLDER&isActive=true'
          );
          setOpenModalBigPath({
            ...openModalBigPath,
            parentId: backendResponse.data.parentId,
            currentValue: {
              id: backendResponse.data.id,
              title: backendResponse.data.title,
              items: backendResponse.data.items
            }
          });
        } catch (error) {
          console.error(error);
        }
        break;
      case 'BACK':
        try {
          service = new Service('folders');
          backendResponse = await service.getSubresource(
            openModalBigPath.parentId,
            'view?type=FOLDER&isActive=true'
          );
          setOpenModalBigPath({
            ...openModalBigPath,
            parentId: backendResponse.data.parentId,
            currentValue: {
              id: backendResponse.data.id,
              title: backendResponse.data.title,
              items: backendResponse.data.items
            }
          });
        } catch (error) {
          console.error(error);
        }
        break;
      case 'SELECT':
        dispatch(bigPathFormModalActionPopUp(openModalBigPath));
        setOpenModalBigPath({
          value: false,
          anchorPosition: null,
          contentName: null,
          parentId: null,
          currentValue: null
        });
        break;
      default:
        break;
    }
  };

  const modalBigPath = () => (
    <Menu
      open={openModalBigPath.value}
      onClose={() => handlerModalBigPath('CLOSE')}
      anchorEl={openModalBigPath.anchorPosition}
      MenuListProps={{
        disablePadding: true
      }}
      TransitionComponent={Fade}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: 'visible',
          boxShadow: '0px 0px 12px rgba(104, 109, 142, 0.24)',
          mt: 0.5
        }
      }}
    >
      <BigPathBox>
        <div className="headBigPathBox">
          <div className="leftHeadBigPathBox">
            {openModalBigPath.parentId === null ? (
              <img width={24} src={images.backBigPathLightModal} />
            ) : (
              <img
                className="imgLeftHeadBigPathBox"
                onClick={() => handlerModalBigPath('BACK')}
                width={24}
                src={images.backBigPathDarkModal}
              />
            )}
          </div>
          <div className="rightHeadBigPathBox">
            <p>{openModalBigPath.currentValue?.title}</p>
          </div>
        </div>
        <div className="bodyBigPathBox">
          {openModalBigPath.currentValue?.items.length === 0 ? (
            <div className="noDataBodyBigPathBox">
              <p>- No Data -</p>
            </div>
          ) : (
            <>
              {openModalBigPath.currentValue?.items.map((value: any) => (
                <div
                  onClick={e => handlerModalBigPath('CLICK', e, value)}
                  className="contentBodyBigPathBox"
                  data-testid={value.title}
                >
                  <img width={20} src={images.folderIconModal} alt="img" />
                  <p>{value.title}</p>
                </div>
              ))}
            </>
          )}
        </div>
        <div className="footBigPathBox">
          <div
            onClick={() => handlerModalBigPath('CLOSE')}
            className="cancelFootBigPathBox"
            data-testid="btnCancel"
          >
            CANCEL
          </div>
          <div
            onClick={() => handlerModalBigPath('SELECT')}
            className="selectFootBigPathBox"
            data-testid="btnSelectFolder"
          >
            SELECT
          </div>
        </div>
      </BigPathBox>
    </Menu>
  );

  // FUNCTION LOGIC

  const closeFormModal = (event?: any, followup?: any) => {
    if (followup) {
      switch (dataFormModal.type) {
        case 'REFACTOR_EXIT_TO_INPUT_FILE_MANAGER':
          dispatch(reducerModifierCloseEventActionPopUp(true));

          const { calId, loggerId, isDrive } = dataFormModal.content.storage;
          dataFormModal.content.storage.history.push({
            pathname: `/input-file/${calId ? calId : loggerId}`,
            state: {
              ...(isDrive && { calculationType: 'BUSINESS' })
            }
          });
          break;
        case 'COMPOSER_DATA_CHANGES_CONFIRMATION':
          handleOverrideCalculation(event, dataFormModal.content.storage);
          break;
        case 'CONFIRMATION_PRINT_VIEW_TO_REPORT':
          const { history, currentReport, isDriveReportManager } =
            dataFormModal.content.storage;
          reset();
          dispatch(formModalSetupActionPopUp('CLOSE'));
          history.push({
            pathname: `/report/${currentReport.calculationId}`,
            state: {
              ...(isDriveReportManager && { calculationType: 'BUSINESS' })
            }
          });
          break;
        case 'LEAVE_REPORT_PRINT_VIEW':
          reset();
          dispatch(formModalSetupActionPopUp('CLOSE'));
          dataFormModal.content.storage.history.push(
            `/logger/${dataFormModal.content.storage.calculationId}/${dataFormModal.content.storage.inputFileId}`
          );
          break;
        case 'SAVE_REPORT_PRINT_VIEW_BREADCRUMB':
          reset();
          dispatch(formModalSetupActionPopUp('CLOSE'));
          const isDriveCalculation =
            dataFormModal.content.storage.isDriveCalculation;
          if (dataFormModal.content.storage.type === 'logger') {
            dataFormModal.content.storage.history.push({
              pathname: `/logger/${dataFormModal.content.storage.calculationId}/${dataFormModal.content.storage.inputFileId}`,
              state: {
                ...(isDriveCalculation && { calculationType: 'BUSINESS' })
              }
            });
          } else if (dataFormModal.content.storage.type === 'composer') {
            dataFormModal.content.storage.history.push({
              pathname: `/composer/${dataFormModal.content.storage.calculationId}/${dataFormModal.content.storage.inputFileId}`,
              state: {
                ...(isDriveCalculation && { calculationType: 'BUSINESS' })
              }
            });
          }
          break;
        case 'UPGRADE_PLAN_SUBSCRIPTION_PRO':
          dispatch(formModalSetupActionPopUp('CLOSE'));
          dispatch(getUserCalculationLimit());
          if (dataFormModal.content.storage) {
            dispatch(dataFormModal.content.storage);
          }
          break;
        case 'SAVE_CONFIRMATION_LOGGER':
          reset();
          dispatch(formModalSetupActionPopUp('CLOSE'));
          const { route, inputFile } = dataFormModal.content.storage;
          if (route === 'print') {
            const newservice = new Service(`report`);
            Promise.all([
              newservice.post({
                inputFileId: inputFile.id
              })
            ]).then(response => {
              const report: any = response[0];
              dataFormModal.content.storage.history.push(
                `/print-view/${report.data.calculationId}/${report.data.inputFileId}/${report.data.id}`
              );
            });
          }
          break;
        case 'UPGRADE_PLAN_SUBSCRIPTION_PRO':
          dispatch(formModalSetupActionPopUp('CLOSE'));
          if (dataFormModal.content.storage) {
            dispatch(dataFormModal.content.storage);
          }
          break;

        case 'REFACTOR_SAVE_CONFIRMATION_LOGGER':
          /**
           * COPY FROM SAVE CONFIRMATION LOGGER
           * need to separate logic between old and new logger modal
           * to prevent error on existing code
           */
          reset();
          dispatch(formModalSetupActionPopUp('CLOSE'));
          if (dataFormModal.content.storage.route === 'print') {
            const newservice = new Service(`report`);
            Promise.all([
              newservice.post({
                inputFileId: dataFormModal.content.storage.inputFile.id
              })
            ]).then(response => {
              const report: any = response[0];
              dataFormModal.content.storage.history.push(
                `/print-view/${report.data.calculationId}/${report.data.inputFileId}/${report.data.id}`
              );
            });
          }
          break;
        /**
         * else: input file is not complete.
         * we keep the user stay in logger view
         */
        case 'SAVE_CONFIRMATION_LOGGER_CHANGES':
          reset();
          dispatch(formModalSetupActionPopUp('CLOSE'));

          const { storage } = dataFormModal.content;
          if (storage.folderId === getPersonalWorkspaceId()) {
            storage.history.push({
              pathname: `/calculations/my-calculation`,
              state: {
                from: 'logger',
                calculationId: storage.calculationId
              }
            });
          } else {
            storage.history.push({
              pathname: `/calculations/my-calculation/${storage.folderId}`,
              state: {
                from: 'logger',
                calculationId: storage.calculationId
              }
            });
          }
          break;
        /**
         * else: input file is not complete.
         * we keep the user stay in logger view
         */
        case 'SAVE_CONFIRMATION_LOGGER_CHANGES':
          reset();
          dispatch(formModalSetupActionPopUp('CLOSE'));
          if (storage.folderId === getPersonalWorkspaceId()) {
            storage.history.push({
              pathname: `/calculations/my-calculation`,
              state: {
                from: 'logger',
                calculationId: storage.calculationId
              }
            });
          } else {
            storage.history.push({
              pathname: `/calculations/my-calculation/${storage.folderId}`,
              state: {
                from: 'logger',
                calculationId: storage.calculationId
              }
            });
          }
          break;
        case 'ERROR_CALCULATION':
          dispatch(formModalSetupActionPopUp('CLOSE'));
          dataFormModal.content.storage.history.goBack({
            state: {
              position: dataFormModal.content.storage.position,
              calculationId: dataFormModal.content.storage.calculationId,
              // added drive id to router state
              ...(dataFormModal.content.storage.driveId && {
                driveId: dataFormModal.content.storage.driveId
              })
            }
          });
          break;
        default:
          reset();
          dispatch(formModalSetupActionPopUp('CLOSE'));
          break;
      }
    } else {
      reset();
      dispatch(formModalSetupActionPopUp('CLOSE'));
    }
  };

  const successSubmitFormModal = async (data: any) => {
    let backendResponse: any;
    try {
      switch (dataFormModal.type) {
        case 'NEW_CALCULATION_FROM_COMPOSER':
          dispatch(submitLoading(true));
          backendResponse = await dispatch(formModalSubmitActionPopUp(data));
          if (backendResponse.loggerPreview) {
            Promise.all([
              dispatch(
                uploadLoggerPreview(
                  window.location.pathname.split('/').reverse()[1],
                  loggerPreview
                )
              )
            ])
              .then(() => {
                dispatch(reducerModifierCloseEventActionPopUp(true));
                history.push(`/composer`);
                setTimeout(() => {
                  history.push(
                    `/composer/${backendResponse.id}/${backendResponse.folderId}`
                  );
                }, 100);
              })
              .catch(error => console.error(error))
              .finally(() => dispatch(submitLoading(false)));
          } else {
            dispatch(reducerModifierCloseEventActionPopUp(true));
            dispatch(submitLoading(false));
            history.push(
              `/composer/${backendResponse.id}/${backendResponse.folderId}`
            );
          }

          break;
        case 'EXIT_CALCULATION_FROM_COMPOSER':
          dispatch(submitLoading(true));
          backendResponse = await dispatch(formModalSubmitActionPopUp(data));
          Promise.all([
            dispatch(
              uploadLoggerPreview(
                window.location.pathname.split('/').reverse()[1],
                loggerPreview
              )
            )
          ])
            .then(() => {
              dispatch(reducerModifierCloseEventActionPopUp(true));
              history.push(
                backendResponse.isDriveCalculation
                  ? `/calculations/businessDrives/${backendResponse.folderId}`
                  : `/calculations/my-calculation`
              );
            })
            .catch(error => console.error(error))
            .finally(() => dispatch(submitLoading(false)));
          break;

        case 'SAVE_CONFIRMATION_LOGGER':
          dispatch(submitLoading(true));
          backendResponse = await dispatch(formModalSubmitActionPopUp(data));
          const newservice = new Service(`report`);
          const { data: resPost } = await newservice.post({
            inputFileId: backendResponse.id
          });
          dispatch(submitLoading(false));
          setTimeout(() => {
            history.push(
              `/print-view/${resPost.calculationId}/${resPost.inputFileId}/${resPost.id}`
            );
          }, 100);
          break;

        case 'REFACTOR_SAVE_CONFIRMATION_LOGGER':
          /**
           * COPY FROM SAVE CONFIRMATION LOGGER
           * need to separate logic between old and new logger modal
           * to prevent error on existing code
           */
          backendResponse = await dispatch(formModalSubmitActionPopUp(data));
          const isDriveCalculation = backendResponse.isDriveCalculation;
          ReportService.createReport(backendResponse.id)
            .then((report: any) => {
              history.push({
                pathname: `/print-view/${report?.calculationId}/${report?.inputFileId}/${report.id}`,
                state: {
                  ...(isDriveCalculation && { calculationType: 'BUSINESS' })
                }
              });
              setTimeout(() => {
                window.location.reload();
              }, 100);
            })
            .catch(e => console.error(e));
          break;

        case 'ACTIVATE_USER_ACCOUNT_MANAGER':
          dispatch(submitLoading(true));
          backendResponse = await dispatch(formModalSubmitActionPopUp(data));
          dispatch(submitLoading(false));
          Promise.resolve(backendResponse);
          break;
        case 'COMPOSER_DATA_CHANGES_INPUT_DATA_CALCULATION':
          dispatch(submitLoading(true));
          backendResponse = await dispatch(formModalSubmitActionPopUp(data));
          dispatch(submitLoading(false));
          history.push(`/composer`);
          setTimeout(() => {
            history.push(
              `/composer/${backendResponse.data.id}/${backendResponse.data.folderId}`
            );
          }, 100);
          break;

        case 'SAVE_REPORT_PRINT_VIEW_CONFIRMATION_TO_MYCALCULATION':
          dispatch(submitLoading(true));
          backendResponse = await dispatch(formModalSubmitActionPopUp(data));
          dispatch(submitLoading(false));
          setTimeout(() => {
            history.push({
              pathname:
                backendResponse.folderId === getPersonalWorkspaceId()
                  ? `/calculations/my-calculation`
                  : `/calculations/my-calculation/${backendResponse.folderId}`,
              state: {
                from: 'print',
                calculationId: backendResponse.calculationId
              }
            });
          }, 100);
          break;

        case 'MODAL_CLEAR_ALL_INPUTS':
          dispatch(reducerModifierCloseEventActionPopUp(true));
          dispatch(setActions('CLEAR_ALL'));
          break;

        case 'MODAL_CLEAR_INPUTS_AT_PAGE':
          dispatch(reducerModifierCloseEventActionPopUp(true));
          dispatch(setActions('CLEAR_AT_PAGE'));
          break;

        default:
          dispatch(submitLoading(true));
          backendResponse = await dispatch(formModalSubmitActionPopUp(data));
          dispatch(submitLoading(false));

          break;
      }
    } catch (error: any) {
      if (!error.response) {
        if (
          error === 'The version can not be empty' ||
          error === 'The input file can not be empty' ||
          error === 'The input file name can not be empty' ||
          error === 'The drive name can not be empty' ||
          error === 'The company name can not be empty' ||
          error === 'Company name is required' ||
          error ===
            "Title length can't be less than 3 chars and more than 60 chars" ||
          error ===
            "Title length can't be less than 3 chars and more than 100 chars"
        ) {
          errorHandler(204, error);
        } else if (
          error === 'Input files name already exist' ||
          error === 'Company name already existing'
        ) {
          errorHandler(400, error);
        }

        dispatch(submitLoading(false));
      } else if (
        error.response.data.status === 404 &&
        error.response.data.message === 'User not found'
      ) {
        errorHandler(404, error.response.data.message);
      } else {
        dispatch(submitLoading(false));
        errorHandler(error.response.data.status, error.response.data.message);
      }
    }
  };

  const handleOverrideCalculation = async (e: any, payload: any) => {
    try {
      await dispatch(
        overrideCalculation(payload.calculationInfo.calculationId)
      );
      dispatch(setCalculationTarget(payload.calculationInfo));
      dispatch(setActionState(payload.action));
      dispatch(reducerModifierCloseEventActionPopUp(true));
      setPreventChangesCalculation({});
      setTimeout(() => {
        dispatch(setPreventChanges(false));
      }, 500);
      return Promise.resolve(payload);
    } catch (error: any) {
      console.error(error);
      dispatch(reducerModifierCloseEventActionPopUp(true));
    }
  };

  const createNewTrigger = async (payload: any) => {
    try {
      if (
        payload.target === 'Create New' ||
        payload.type === 'START_CALCULATION'
      ) {
        dispatch(
          formModalSetupActionPopUp(
            'CREATE_INPUT_FILE_FROM_COMPOSER_NAVBAR',
            dataFormModal.content.storage
          )
        );
      }
    } catch (error: any) {
      console.error(error);
    }
  };

  // ERROR HANDLER

  const errorHandler = (status: any, message: any) => {
    switch (status) {
      case 422:
        switch (message) {
          case 'There is only one Workpage in this calculation':
            closeFormModal();
            dispatch(toastSetupActionPopUp('ERROR', message));
            break;
          case 'Title has already exist':
            setError('title', {
              type: 'manual',
              message: 'Folder name already existing'
            });
            break;
          case findError(message, 'Failed to duplicate the file'):
            closeFormModal();
            dispatch(
              toastSetupActionPopUp('ERROR', 'Failed to duplicate the file')
            );
            break;
          case findError(message, 'Input file name already exists'):
            setError('inputFile', { type: 'manual', message: message });
            break;
          case findError(
            message,
            'Not allowed to use duplicated calculation title in drive'
          ):
            setError('title', {
              type: 'manual',
              message: 'Calculation Name already exist. Please use other name'
            });
            break;
          case 'This email has been registered already':
            setError('email', {
              type: 'manual',
              message
            });
            break;
          default:
            console.error(message);
            break;
        }
        break;
      case 400:
        switch (message) {
          case 'Title has already existed':
            switch (dataFormModal.type) {
              case 'PUBLISH_TO_ENTERPRISE_CALCULATION_FROM_COMPOSER':
                closeFormModal();
                dispatch(
                  toastSetupActionPopUp(
                    'ERROR',
                    `Cannot Publish because ${dataHeadOfCalculation.title} is Already Exist in the Enterprise Calculation`
                  )
                );
                break;
              case 'PUBLISH_TO_COMMUNITY_CALCULATION_FROM_COMPOSER':
                closeFormModal();
                dispatch(
                  toastSetupActionPopUp(
                    'ERROR',
                    `Cannot Publish because ${dataHeadOfCalculation.title} is Already Exist in the Community Calculation`
                  )
                );
                break;
              case 'PUBLISH_TO_ENTERPRISE_CALCULATION_FROM_MYCALCULATION':
                closeFormModal();
                dispatch(
                  toastSetupActionPopUp(
                    'ERROR',
                    `Cannot Publish because ${calName} is Already Exist in the Enterprise Calculation`
                  )
                );
                break;
              case 'PUBLISH_TO_COMMUNITY_CALCULATION_FROM_MYCALCULATION':
                closeFormModal();
                dispatch(
                  toastSetupActionPopUp(
                    'ERROR',
                    `Cannot Publish because ${calName} is Already Exist in the Community Calculation`
                  )
                );
                break;
            }
            break;
          case 'This email has been registered already':
            switch (dataFormModal.type) {
              case 'ADD_USER_USER_MANAGEMENT':
                closeFormModal();
                dispatch(toastSetupActionPopUp('ERROR', `${message}`));
                break;
            }
            break;
          case 'Current password does not match':
            setError('currentPassword', {
              type: 'manual',
              message: 'Password do not match'
            });
            break;
          case 'Password does not match':
            setError('confirmNewPassword', {
              type: 'manual',
              message: 'Password do not match'
            });
            break;
          case 'Passwords do not match':
            setError('confirmNewPassword', {
              type: 'manual',
              message: 'Password do not match'
            });
            dispatch(submitLoading(false));
            break;
          case "New password can't be the same as current password":
            setError('newPassword', { type: 'manual', message: message });
            dispatch(submitLoading(false));
            break;

          case 'Email already exists':
            switch (dataFormModal.type) {
              case 'EDIT_ACCOUNT_MANAGER':
                setError('email', { type: 'manual', message: message });
                break;
              case 'EDIT_EMAIL_ACCOUNT_SETTING':
                setError('email', { type: 'manual', message: message });
            }
            break;

          case 'Company name already existing':
            setError('name', {
              type: 'manual',
              message
            });
            dispatch(submitLoading(false));
            break;

          default:
            console.error(message);
            break;
        }
        break;
      case 204: //error handling for empty dropdown
        switch (message) {
          case 'The version can not be empty':
            setError('version', { type: 'manual', message: message });
            break;
          case 'The input file can not be empty':
            setError('inputFile', { type: 'manual', message: message });
            break;
          case 'The input file name can not be empty':
            setError('inputFile', { type: 'manual', message: message });
            break;
          case 'The drive name can not be empty':
            setError('title', { type: 'manual', message: message });
            break;
          case "Title length can't be less than 3 chars and more than 100 chars":
          case "Title length can't be less than 3 chars and more than 60 chars":
            setError('title', { type: 'manual', message: message });
            break;
          case 'The company can not be empty':
            setError('company', { type: 'manual', message: message });
            break;
          case 'Company name is required':
            setError('name', { type: 'manual', message });
            break;
          default:
            console.error(message);
            break;
        }
        break;
      case 403:
        switch (message) {
          case 'Only the creator can delete this calculation':
            closeFormModal();
            dispatch(toastSetupActionPopUp('ERROR', `${message}`));
            break;
          case 'PRO user cannot have more than 10 calculations in personal workspace':
          case 'Unable to have calculations in drive more than 10':
          case 'FREE user cannot have more than 3 calculations in personal workspace':
            dispatch(
              formModalSetupActionPopUp('UPGRADE_PLANS_POPUP', subscription)
            );
            break;
          default:
            closeFormModal();
            console.error(message);
            break;
        }
        break;
      case 404:
        switch (message) {
          case 'User not found':
            setError('email', { type: 'manual', message: message });
            dispatch(submitLoading(false));
            break;
          default:
            closeFormModal();
            console.error(message);
            break;
        }
        break;
      default:
        console.error(status);
        break;
    }
  };

  const renderPreview = (data: any) => {
    return (
      <PreviewContainer>
        <ImagePreview>
          <img
            id="imagePreview"
            src={
              data.calculation?.preview + `?noCache=` + getRandomNumber(1)[0]
            }
            alt="img"
          />
        </ImagePreview>
        <PreviewDesc>
          <Text
            variant="h1"
            align={dataFormModal.content.headAlign}
            style={{ marginBottom: '24px' }}
          >
            {data.calculation.title}
          </Text>
          {dataFormModal.content.body.map((value: any, index: number) => (
            <Label align={value.labelAlign} key={`label-body-${index}`}>
              {value.label}
            </Label>
          ))}
          <div id="originator">
            <Text variant="body3" color={theme.customText.disable}>
              Originator
            </Text>
            <Text variant="body2" color={theme.customText.high}>
              {data?.calculation.createdBy?.name}
            </Text>
          </div>

          <Form onSubmit={handleSubmit(successSubmitFormModal)}>
            <Button
              key="copytomycalc"
              color="primary"
              variant="contained"
              sx={{
                flexDirection: 'column',
                bottom: '54px',
                position: 'absolute',
                width: '342px'
              }}
              disabled={false}
              type="submit"
            >
              <Text variant="h5" color="inherit">
                COPY TO MY CALC
              </Text>
              <Text variant="smallRegular">
                You can start and edit this template
              </Text>
            </Button>
          </Form>
        </PreviewDesc>
      </PreviewContainer>
    );
  };

  const RenderStartCalculationErrorConfirmation = () => {
    return (
      <div>
        <Text variant="body2" textAlign={'center'} color={theme.customText.low}>
          Please check the following things before moving on to ensure an
          error-free logging experience:
        </Text>
        <InformationWrapper>
          <div id="line">
            <Icon icon="dot" color={theme.customText.low} />
            <Text variant="body2" color={theme.customText.low}>
              Correct variable names are used
            </Text>
          </div>
          <div id="line">
            <Icon icon="dot" color={theme.customText.low} />
            <Text variant="body2" color={theme.customText.low}>
              Prevent #REF by correctly referencing a variable
            </Text>
          </div>
          <div id="line">
            <Icon icon="dot" color={theme.customText.low} />
            <Text variant="body2" color={theme.customText.low}>
              Adjust placeholder inputs to avoid invalid output
            </Text>
          </div>
          <div id="line">
            <Icon icon="dot" color={theme.customText.low} />
            <Text variant="body2" color={theme.customText.low}>
              Prevent #VALUE by using the right type of data in formulas
            </Text>
          </div>
        </InformationWrapper>
      </div>
    );
  };

  // RENDER

  return (
    <Modal
      open={dataFormModal.isOpen}
      onBackdropClick={e => closeFormModal(e, dataFormModal?.followUp)}
    >
      <>
        <FlyingContainer>
          <Container
            upSize={dataFormModal.content.upSize}
            preview={dataFormModal.content.preview}
            isDrive={dataFormModal.content.isDrive}
            upgrade={dataFormModal.content.upgrade}
            copyToDrives={
              dataFormModal.type === 'COPY_CALCULATION_TO_BUSINESS_DRIVE'
            }
          >
            <XButton>
              <img
                onClick={e => closeFormModal(e, dataFormModal?.followUp)}
                src={images.xButtonModal}
                width={12}
              />
            </XButton>
            {dataFormModal.content.preview ? (
              renderPreview(dataFormModal.content.storage)
            ) : dataFormModal.content.isDrive ? (
              <ManageMembers
                folderId={dataFormModal.content.storage.folderId}
                onClose={closeFormModal}
              />
            ) : dataFormModal.content.upgrade ? (
              <UpgradePlansPopUp
                plan={dataFormModal.content.storage}
                onClose={closeFormModal}
                contentHead={dataFormModal.content.head}
              />
            ) : dataFormModal.type === 'COPY_CALCULATION_TO_BUSINESS_DRIVE' ? (
              <CopyToDrives
                calculation={dataFormModal.content.storage}
                onClose={closeFormModal}
                getDriveContent={id => dispatch(fetchTeamDrives(id))}
                handleModal={(type, payload) =>
                  dispatch(formModalSetupActionPopUp(type, payload))
                }
              />
            ) : (
              <>
                <Text variant="h1" align={dataFormModal.content.headAlign}>
                  {dataFormModal.content.head}
                </Text>
                <Form onSubmit={handleSubmit(successSubmitFormModal)}>
                  <Body>
                    {dataFormModal.type ===
                      'START_CALCULATION_ERROR_CONFIRMATION' && (
                      <RenderStartCalculationErrorConfirmation />
                    )}
                    {dataFormModal.content.body.map((value: any) =>
                      value.type === 'TEXT' ? (
                        <Controller
                          key={value.name}
                          name={value.name}
                          control={control}
                          render={({ field }) => {
                            return (
                              <>
                                {!value.inputPlaceHolder && (
                                  <LabelInputText>
                                    {value.placeholder}
                                  </LabelInputText>
                                )}
                                <InputText
                                  {...field}
                                  type="text"
                                  className="textOnBodyFormModal"
                                  placeholder={value.placeholder}
                                  maxLength={value.limit}
                                  disabled={value.isDisabled}
                                  error={errors[value.name]}
                                  data-testid={value.dataTestid}
                                />
                                {errors[value.name] ? (
                                  <ErrorInputText>
                                    {errors[value.name]?.message}
                                  </ErrorInputText>
                                ) : (
                                  <></>
                                )}
                              </>
                            );
                          }}
                        />
                      ) : value.type === 'NUMBER' ? (
                        <Controller
                          key={value.name}
                          name={value.name}
                          control={control}
                          render={({ field }) => {
                            return (
                              <>
                                {!value.inputPlaceHolder && (
                                  <LabelInputText>
                                    {value.placeholder}
                                  </LabelInputText>
                                )}
                                <InputText
                                  {...field}
                                  type="number"
                                  className="textOnBodyFormModal"
                                  placeholder={value.placeholder}
                                  maxLength={value.limit}
                                  disabled={value.isDisabled}
                                  error={errors[value.name]}
                                  data-testid={value.dataTestid}
                                />
                                {errors[value.name] ? (
                                  <ErrorInputText>
                                    {errors[value.name]?.message}
                                  </ErrorInputText>
                                ) : (
                                  <></>
                                )}
                              </>
                            );
                          }}
                        />
                      ) : value.type === 'LABEL' ? (
                        <div key={value.name}>
                          <Text
                            variant="h1"
                            style={{ textAlign: 'center', paddingBottom: 8 }}
                          >
                            {value.boldLabel}
                          </Text>
                          <Label align={value.labelAlign}>
                            {value.label}
                            {value.subLabel && (
                              <div>
                                {value.subLabel}
                                <div style={{ overflowWrap: 'anywhere' }}>
                                  <strong>
                                    {dataFormModal.content.storage.title}
                                  </strong>
                                </div>
                              </div>
                            )}
                          </Label>
                        </div>
                      ) : value.type === 'CHECKBOX' ? (
                        <Controller
                          key={value.name}
                          name={value.name}
                          control={control}
                          render={({ field }) => (
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                                marginTop: 2
                              }}
                            >
                              <Checkbox
                                disabled={value.isDisabled}
                                sx={{ padding: 0 }}
                                onChange={e => field.onChange(e.target.checked)}
                                checked={Boolean(field.value)}
                              />
                              <LabelInputText style={{ paddingTop: 5 }}>
                                {value.placeholder}
                              </LabelInputText>
                            </Box>
                          )}
                        />
                      ) : value.type === 'SELECT' ? (
                        <>
                          {!value.inputPlaceHolder && (
                            <LabelInputText>{value.placeholder}</LabelInputText>
                          )}
                          {value?.label && (
                            <LabelInputText>{value.label}</LabelInputText>
                          )}
                          <Controller
                            key={value.name}
                            name={value.name}
                            control={control}
                            render={() => {
                              return (
                                <>
                                  <InputSelect
                                    onClick={(e: any) =>
                                      value.isDisabled
                                        ? ''
                                        : handlerModalSelect('OPEN', e, {
                                            name: value.name,
                                            contentValue: value.contentValue,
                                            createNew: value.createNew,
                                            loadNew: value.loadNew,
                                            staticTitle: value.staticTitle
                                          })
                                    }
                                    error={errors[value.name]}
                                    data-testid={value.dataTestid}
                                    disabled={value.isDisabled}
                                    style={{
                                      marginBottom: checkTitleName(value.name)
                                    }}
                                  >
                                    {value.value === null ? (
                                      <p className="defaultValueSelect">
                                        {value.placeholder}
                                      </p>
                                    ) : (
                                      <p className="valueSelect">
                                        {value.value}
                                      </p>
                                    )}
                                    <img width={16} src={images.selectModal} />
                                  </InputSelect>
                                  {errors[value.name] ? (
                                    <ErrorInputText>
                                      {errors[value.name]?.message}
                                    </ErrorInputText>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              );
                            }}
                          />
                        </>
                      ) : value.type === 'BIGPATH' ? (
                        <BigPath
                          key={value.name}
                          onClick={(e: any) =>
                            handlerModalBigPath('OPEN', e, {
                              name: value.name,
                              parentId: value.parentId,
                              currentValue: value.currentValue
                            })
                          }
                          data-testid={value.dataTestid}
                        >
                          <p className="valueSelect">
                            {value.currentValue.title}
                          </p>
                          <img width={16} src={images.selectModal} />
                        </BigPath>
                      ) : value.type === 'AUTOCOMPLETE' ? (
                        <div style={{ paddingBottom: '16px' }} key={value.name}>
                          <Controller
                            control={control}
                            name={value.name}
                            rules={{ required: true }}
                            render={({ field: { onChange } }) => (
                              <NoPaddingAutocomplete
                                freeSolo
                                size={'small'}
                                autoComplete={true}
                                onChange={(event, item) => {
                                  onChange(item);
                                }}
                                onInputChange={(event, newInputValue) => {
                                  onChange(newInputValue);
                                  // setInputValue(newInputValue);
                                }}
                                renderOption={(props, option: any) => (
                                  <li
                                    {...props}
                                    style={{
                                      fontSize: '12px',
                                      color: '#353C68'
                                    }}
                                  >
                                    {option}
                                  </li>
                                )}
                                value={value.defaultValue}
                                options={value.contentValue.map(
                                  (options: any) => options.title
                                )}
                                // inputValue={inputValue}
                                renderInput={params => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    type="text"
                                    error={!!errors[value.name]}
                                    placeholder={value.placeholder}
                                    helperText={
                                      errors[value.name] &&
                                      `${value.name} is required`
                                    }
                                    inputProps={{
                                      ...params.inputProps,
                                      maxLength: value.limit,
                                      autoComplete: 'disabled' // disable autocomplete and autofill
                                    }}
                                  />
                                )}
                              />
                            )}
                          />
                        </div>
                      ) : value.type === 'BIGPATH' ? (
                        <BigPath
                          key={value.name}
                          onClick={(e: any) =>
                            handlerModalBigPath('OPEN', e, {
                              name: value.name,
                              parentId: value.parentId,
                              currentValue: value.currentValue
                            })
                          }
                          data-testid={value.dataTestid}
                        >
                          <p className="valueSelect">
                            {value.currentValue.title}
                          </p>
                          <img width={16} src={images.selectModal} />
                        </BigPath>
                      ) : value.type === 'AUTOCOMPLETE' ? (
                        <div style={{ paddingBottom: '16px' }} key={value.name}>
                          <Controller
                            control={control}
                            name={value.name}
                            rules={{ required: true }}
                            render={({ field: { onChange } }) => (
                              <NoPaddingAutocomplete
                                freeSolo
                                size={'small'}
                                autoComplete={true}
                                onChange={(event, item) => {
                                  onChange(item);
                                }}
                                onInputChange={(event, newInputValue) => {
                                  onChange(newInputValue);
                                  // setInputValue(newInputValue);
                                }}
                                renderOption={(props, option: any) => (
                                  <li
                                    {...props}
                                    style={{
                                      fontSize: '12px',
                                      color: '#353C68'
                                    }}
                                  >
                                    {option}
                                  </li>
                                )}
                                value={value.defaultValue}
                                options={value.contentValue.map(
                                  (options: any) => options.title
                                )}
                                // inputValue={inputValue}
                                renderInput={params => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    type="text"
                                    error={!!errors[value.name]}
                                    placeholder={value.placeholder}
                                    helperText={
                                      errors[value.name] &&
                                      `${value.name} is required`
                                    }
                                    inputProps={{
                                      ...params.inputProps,
                                      maxLength: value.limit,
                                      autoComplete: 'disabled' // disable autocomplete and autofill
                                    }}
                                  />
                                )}
                              />
                            )}
                          />
                        </div>
                      ) : value.type === 'PASSWORD' ? (
                        <div style={{ paddingBottom: 16 }} key={value.name}>
                          <Controller
                            name={value.name}
                            control={control}
                            render={({ field }) => {
                              return (
                                <>
                                  {!value.inputPlaceHolder && (
                                    <LabelInputText>
                                      {value.placeholder}
                                    </LabelInputText>
                                  )}
                                  <Input
                                    {...field}
                                    size="small"
                                    fullWidth
                                    // type={typePassword? 'password':'text'}
                                    type={getVariable(value.name)}
                                    error={errors[value.name] as any}
                                    disabled={value.isDisabled}
                                    placeholder={value.placeholder}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <IconButton
                                            onClick={() =>
                                              changePasswordShow(value.name)
                                            }
                                          >
                                            {value.name ===
                                              'currentPassword' && (
                                              <Icon
                                                icon={
                                                  typePassword
                                                    ? 'eyeOff'
                                                    : 'eyeOn'
                                                }
                                              />
                                            )}
                                            {value.name === 'newPassword' && (
                                              <Icon
                                                icon={
                                                  newPassword
                                                    ? 'eyeOff'
                                                    : 'eyeOn'
                                                }
                                              />
                                            )}
                                            {value.name ===
                                              'confirmNewPassword' && (
                                              <Icon
                                                icon={
                                                  confirmNewPassword
                                                    ? 'eyeOff'
                                                    : 'eyeOn'
                                                }
                                              />
                                            )}
                                          </IconButton>
                                        </InputAdornment>
                                      ),
                                      style: {
                                        fontSize: 12,
                                        padding: '3px 3px 3px 0px'
                                      }
                                    }}
                                  />
                                  {errors[value.name] ? (
                                    <ErrorInputText>
                                      {errors[value.name]?.message}
                                    </ErrorInputText>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              );
                            }}
                          />
                        </div>
                      ) : value.type === 'IMAGE' ? (
                        <div
                          style={{
                            display: 'flex',
                            width: value.image ? '' : '200px',
                            justifyContent: value.image ? 'center' : ''
                          }}
                          key={value.name}
                        >
                          {value.image ? (
                            <div
                              style={{
                                width: 143,
                                height: 143,
                                textAlign: 'center',
                                backgroundImage: `url(${value?.image})`,
                                backgroundPosition: 'center',
                                backgroundSize: 'cover'
                              }}
                            >
                              <h1
                                style={{
                                  width: 143,
                                  paddingTop: '15%',
                                  marginBottom: 0,
                                  color: '#FFFFFF'
                                }}
                              >
                                {dataFormModal.content.storage?.text?.title}
                              </h1>
                              <p
                                style={{
                                  fontSize: 10,
                                  fontWeight: 700,
                                  color: '#FFFFFF'
                                }}
                              >
                                Until{' '}
                                {dataFormModal.content.storage?.text?.subtitle}
                              </p>
                            </div>
                          ) : (
                            <></>
                          )}

                          <div style={{ paddingLeft: 20 }}>
                            <Text variant="h5" style={{ paddingBottom: 20 }}>
                              {value?.text}
                            </Text>
                            {value?.listItem?.map((item: any) => (
                              <ListItemIcon style={{ paddingBottom: 16 }}>
                                <CheckCircleIcon
                                  sx={{
                                    width: 16,
                                    height: 16,
                                    color: item.disabled ? '' : '#118CFF'
                                  }}
                                />
                                <Text
                                  variant="body3"
                                  style={{
                                    paddingLeft: 14,
                                    textAlign: 'start'
                                  }}
                                >
                                  {item}
                                </Text>
                              </ListItemIcon>
                            ))}
                            <Text
                              variant="h6"
                              color={'#118CFF'}
                              style={{ cursor: 'pointer' }}
                            >
                              {value.textButton}
                            </Text>
                          </div>
                        </div>
                      ) : value.type === 'IMAGE_STATIC' ? (
                        <div
                          style={{
                            display: 'flex',
                            width: value.image ? '' : '200px',
                            justifyContent: value.image ? 'center' : ''
                          }}
                          key={value.name}
                        >
                          {value.image ? (
                            <img
                              src={value.image}
                              style={{
                                width: 143,
                                height: 143,
                                textAlign: 'center'
                              }}
                            />
                          ) : (
                            <></>
                          )}
                          <div style={{ paddingLeft: 20 }}>
                            <Text variant="h5" style={{ paddingBottom: 20 }}>
                              {value?.text}
                            </Text>
                            {value?.listItem?.map((item: any) => (
                              <ListItemIcon style={{ paddingBottom: 16 }}>
                                <CheckCircleIcon
                                  sx={{
                                    width: 16,
                                    height: 16,
                                    color: item.disabled ? '' : '#118CFF'
                                  }}
                                />
                                <Text
                                  variant="body3"
                                  style={{
                                    paddingLeft: 14,
                                    textAlign: 'start'
                                  }}
                                >
                                  {item}
                                </Text>
                              </ListItemIcon>
                            ))}
                            <Text variant="h6" color={'#118CFF'}>
                              {value.textButton}
                            </Text>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )
                    )}
                    {dataFormModal.content.textButton ? (
                      <>
                        <Text
                          variant="h5"
                          color="primary"
                          onClick={() =>
                            dataFormModal?.content?.textButton.onClick()
                          }
                          style={{ cursor: 'pointer' }}
                        >
                          {dataFormModal.content?.textButton?.label}
                        </Text>
                      </>
                    ) : (
                      <></>
                    )}
                  </Body>

                  <Foot>
                    {dataFormModal?.content?.foot?.map((value: any) =>
                      value.type === 'CANCEL' ? (
                        <StyledButton
                          key={value.label}
                          type="button"
                          variant="contained"
                          fullWidth
                          data-testid={value.dataTestid}
                          onClick={e => closeFormModal(e, value.followup)}
                        >
                          {value.label}
                        </StyledButton>
                      ) : value.type === 'CANCEL_OUTLINED' ? (
                        <StyledButton
                          key={value.label}
                          type="button"
                          variant="outlined"
                          fullWidth
                          data-testid={value.dataTestid}
                          onClick={e => closeFormModal(e, value.followup)}
                        >
                          {value.label}
                        </StyledButton>
                      ) : value.type === 'SUBMIT' ? (
                        <StyledButton
                          key={value.label}
                          type="submit"
                          variant="contained"
                          fullWidth
                          data-testid={value.dataTestid}
                        >
                          {isLoadingSubmit ? <Loading /> : value.label}
                        </StyledButton>
                      ) : value.type === 'DANGER' ? (
                        <StyledButton
                          key={value.label}
                          type="submit"
                          color="error"
                          variant="contained"
                          fullWidth
                          data-testid={value.dataTestid}
                        >
                          {isLoadingSubmit ? <Loading /> : value.label}
                        </StyledButton>
                      ) : (
                        <></>
                      )
                    )}
                  </Foot>
                </Form>
              </>
            )}
          </Container>
        </FlyingContainer>
        <>
          {modalSelect()}
          {modalBigPath()}
        </>
      </>
    </Modal>
  );
}
