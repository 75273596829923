// debounce.ts
export const debounce = <T extends (...args: any[]) => void>(
  func: T,
  wait: number
) => {
  let timeout: NodeJS.Timeout | null = null;

  // The debounced function
  const debounced = (...args: any[]) => {
    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => {
      func(...args);
    }, wait);
  };

  // The cancel method to clear the timeout
  debounced.cancel = () => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
  };

  // Return the debounced function along with cancel method
  return debounced;
};

// isString.ts
export const isString = (value: any): boolean => {
  return typeof value === 'string';
};

// isNan.ts
export const isNaN = (value: any): boolean => {
  return typeof value === 'number' && isNaN(value);
};

export const decodeUnicodeEscapeSequences = (str: string): string => {
  // Decode Unicode escape sequences (\uXXXX)
  str = str.replace(/\\u([0-9a-fA-F]{4})/g, (_, hex) =>
    String.fromCodePoint(parseInt(hex, 16))
  );

  // Convert \xHH sequences into raw bytes
  const bytes = str.replace(/\\x([0-9a-fA-F]{2})/g, (_, hex) =>
    String.fromCharCode(parseInt(hex, 16))
  );

  // Convert the string into a Uint8Array for proper UTF-8 decoding
  const encoder = new TextEncoder();
  const byteArray = encoder.encode(bytes); // Properly encode the string

  // Use TextDecoder to properly interpret UTF-8 encoded bytes
  return new TextDecoder('utf-8').decode(byteArray);
};

export const toTitleCase = (str: string, role?: boolean) => {
  let txt: string = str;
  if (role) {
    if (txt === 'COMPANY_ADMIN') {
      txt = 'ADMIN';
    } else {
      txt = 'USER';
    }
  }
  return txt
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .replace(/_/g, ' ')
    .toLowerCase()
    .replace(/\b\w/g, char => char.toUpperCase());
};
